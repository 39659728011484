<template>
  <div class="vg_wrapper">
    <el-form ref="prodForm" :model="prodForm" :rules="rules" label-width="120px" size="mini">
      <add-header
        v-sticky
        :formData="prodForm"
        :stateFlag="true"
        style="padding-top: 16px; background: white"
        @cancel="cancel"
        @submit="submit('prodForm')"
      ></add-header>
      <el-row>
        <el-row :gutter="20" class="vg_mb_5">
          <el-col :span="24">
            <h3 style="color: #606266" class="vg_mb_5">基本信息</h3>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-form-item label="属性" prop="prod_attr">
              <el-radio @change="prodAttrChange" v-model="prodForm.prod_attr" label="1">商品</el-radio>
              <el-radio @change="prodAttrChange" v-model="prodForm.prod_attr" label="2">部件</el-radio>
              <el-radio @change="prodAttrChange" v-model="prodForm.prod_attr" label="3">商品既部件</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :span="8" v-if="prodForm.prod_attr === '1'">
              <el-form-item label="是否是套装" prop="is_suit">
                <el-radio-group v-model="prodForm.is_suit" @input="val => (val === '1' ? (prodForm.prod_no = 'ZS') : '')">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="prodForm.prod_attr !== '2'">
              <el-form-item label="种类" prop="prod_type">
                <el-radio-group v-model="prodForm.prod_type">
                  <el-radio :label="1">Classic</el-radio>
                  <el-radio :label="2">SuperChewer</el-radio>
                  <el-radio :label="3">Home</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="prodForm.prod_attr !== '1'">
              <el-form-item
                label="部件名称"
                prop="prod_partno"
                :rules="prodForm.prod_attr !== '1' ? [{ required: true, message: ' ' }] : [{ required: false, message: ' ' }]"
              >
                <el-input
                  maxlength="20"
                  @change="() => changePart('prod_partno')"
                  :disabled="prodForm.prod_attr === '1'"
                  @input="prodForm.prod_partno = helper.keepSpace2(prodForm.prod_partno)"
                  v-model="prodForm.prod_partno"
                  placeholder="请填写部件名称"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :span="8">
              <el-form-item label="中文名称" prop="prod_name">
                <el-input
                  v-model="prodForm.prod_name"
                  maxlength="100"
                  placeholder="请填写中文名称"
                  show-word-limit
                  @blur="prodForm.prod_attr === '3' ? (prodForm.prod_partno = prodForm.prod_name) : ''"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="英文名称" prop="prod_ename">
                <el-input maxlength="100" v-model="prodForm.prod_ename" placeholder="请填写英文名称" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户简称" prop="cust_id">
                <el-select v-model="prodForm.cust_id" clearable filterable size="small">
                  <el-option v-for="item in custs" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"></el-option>
                </el-select>
                <!--                <el-input disabled v-model="prodForm.cust_abbr" placeholder="请填写客户简称" show-word-limit>-->
                <!--                  <template slot="append">-->
                <!--                    <el-link type="primary" class="vg_cursor_hander" @click="dialogVisible3 = true">选择</el-link>-->
                <!--                  </template>-->
                <!--                </el-input>-->
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :span="8">
              <el-form-item label="我司货号" prop="prod_no">
                <!--@input="prodForm.prod_no = keepLetter2AndNumber5(prodForm.prod_no)"-->
                <el-input
                  v-model="prodForm.prod_no"
                  placeholder="请填写我司货号"
                  show-word-limit
                  @input="val => (prodForm.prod_no = keepLetter2AndNumber5(val))"
                  :maxlength="prodNoMaxLength"
                  :disabled="prodNoDisabled"
                >
                  <template slot="append" v-if="ifProdAttr">
                    <el-link type="primary" class="vg_cursor_hander" @click="importPordNo">选择</el-link>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="货号后缀" prop="prod_poststfix">
                <el-input
                  maxlength="10"
                  v-model="prodForm.prod_poststfix"
                  @input="prodForm.prod_poststfix = helper.keepEngNum(prodForm.prod_poststfix)"
                  placeholder="请填写货号后缀"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户货号" prop="prod_cust_no">
                <el-input
                  maxlength="20"
                  v-model="prodForm.prod_cust_no"
                  @input="prodForm.prod_cust_no = helper.keepSpace2(prodForm.prod_cust_no)"
                  placeholder="请填写客户货号"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :span="8" v-if="isBussinessDept">
              <el-form-item label="销售价" prop="prod_usd">
                <el-input
                  maxlength="9"
                  v-model="prodForm.prod_usd"
                  @input="val => (prodForm.prod_usd = keep4Decimal(val))"
                  placeholder="请填写销售价"
                  show-word-limit
                >
                  <template slot="append">{{ prodForm.cust_currency === 'RMB' ? '元' : '美元' }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="isBussinessDept">
              <el-form-item label="折扣价" prop="prod_dusd">
                <el-input
                  maxlength="9"
                  v-model="prodForm.prod_dusd"
                  @input="prodForm.prod_dusd = helper.keepTNum1(prodForm.prod_dusd)"
                  @blur="prodForm.prod_dusd = helper.calcPrice(prodForm.prod_dusd, 4, 10000)"
                  placeholder="请填写折扣价"
                  show-word-limit
                >
                  <template slot="append">{{ prodForm.cust_currency === 'RMB' ? '元' : '美元' }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="isBussinessDept">
              <el-form-item label="美金报价" prop="prod_usd_price">
                <el-input
                  v-model="prodForm.prod_usd_price"
                  @input="prodForm.prod_usd_price = helper.keepTNum1(prodForm.prod_usd_price)"
                  @blur="prodForm.prod_usd_price = helper.calcPrice(prodForm.prod_usd_price, 4, 10000)"
                  placeholder="请填写美金报价"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5" v-if="prodForm.prod_attr !== '2'">
            <el-col :span="8" v-if="isBussinessDept">
              <el-form-item label="导入Qarma" prop="qarma">
                <el-radio-group v-model="prodForm.qarma">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <!--            2024-1-31 16:16:16 取消销售经办人-->
            <!--            <el-col :md="8">-->
            <!--              <el-form-item label="销售经办人" prop="scon_stff_id">-->
            <!--                <el-select v-model="prodForm.scon_stff_id" placeholder="请选择销售经办人" size="small" clearable filterable>-->
            <!--                  <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>-->
            <!--                </el-select>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <el-col :span="8">
              <el-col :span="12">
                <el-form-item label="单位" prop="prod_unit" label-width="110px">
                  <el-select filterable v-model="prodForm.prod_unit" placeholder="请选择单位">
                    <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="币种" prop="cust_currency" :rules="[{ required: prodForm.prod_attr !== '2', trigger: ['blur', 'change'], message: ' ' }]">
                  <el-select filterable v-model="prodForm.cust_currency" placeholder="请选择销售币种">
                    <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :md="8">
              <el-form-item label="老ERP货号" prop="rich_prod_no">
                <el-input v-model="prodForm.rich_prod_no" maxlength="50" placeholder="暂无" show-word-limit />
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :md="8">
              <el-form-item :label="prodForm.prod_attr === '1' || prodForm.prod_attr === '3' ? '商品版本号' : '部件版本号'" prop="prod_version_no">
                <el-input v-model="prodForm.prod_version_no" maxlength="5" placeholder="请填写版本号" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item :label="prodForm.prod_attr === '1' || prodForm.prod_attr === '3' ? '商品价格备注' : '部件价格备注'" prop="price_remarks">
                <el-input v-model="prodForm.price_remarks" placeholder="请填写" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" v-if="prodForm.prod_attr === '2'">
              <el-form-item label="单位" prop="prod_unit">
                <el-select filterable v-model="prodForm.prod_unit" placeholder="请选择单位">
                  <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :span="8" v-if="prodForm.prod_attr === '2'">
              <el-form-item label="币种" prop="cust_currency" :rules="[{ required: true, trigger: ['blur', 'change'], message: ' ' }]">
                <el-select filterable v-model="prodForm.cust_currency" placeholder="请选择销售币种">
                  <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :rules="[{ required: true, trigger: ['blur', 'change'], message: ' ' }]" label="客户品牌" prop="cust_brand">
                <el-select v-model="prodForm.cust_brand" filterable placeholder="请选择客户品牌(无数据请去客户信息维护)">
                  <el-option v-for="item in custBrandList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <h3 style="color: #606266" class="vg_mb_5 flexV">
              图片信息
              <!--<h6><span style="color: red">*至少有一张图片</span></h6>-->
            </h3>
          </el-col>
          <el-col :span="24">
            <el-col :span="8">
              <el-form-item label="商品工艺图" prop="imge_id">
                <div class="vd_dis">
                  <div>
                    <el-upload
                      class="upload-demo"
                      drag
                      :action="uploadUrl"
                      :data="dataBody"
                      :show-file-list="false"
                      :on-success="res => handleAvatarSuccess(res, 1)"
                      :before-upload="beforeAvatarUpload"
                      ref="uploadMutiple1"
                    >
                      <imgPopover v-if="prodForm.imge_id" :imgeUrl="prodForm.imge_url" :imgeUrlO="prodForm.imge_urlO"></imgPopover>
                      <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
                    </el-upload>
                    <span>大小不能超过3MB</span>
                  </div>
                  <div id="preview" class="vd_flex_right" style="display: flex; flex-direction: column" @paste="e => handlePaste(e, 1)">
                    <span><i class="el-icon-s-opportunity" style="color: #fb894c"></i>点击此处并按Ctrl+V粘贴</span>
                    <el-tooltip content="清除商品工艺图" effect="dark" placement="right" style="margin-left: auto">
                      <el-button icon="el-icon-delete" type="text" @click="clearImage(1)"></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商品签字样" prop="imge_commodity">
                <div class="vd_dis">
                  <div>
                    <el-upload
                      ref="uploadMutiple2"
                      class="upload-demo"
                      drag
                      :action="uploadUrl"
                      :data="dataBody"
                      :show-file-list="false"
                      :on-success="res => handleAvatarSuccess(res, 2)"
                      :before-upload="beforeAvatarUpload"
                    >
                      <imgPopover v-if="prodForm.imge_commodity" :imgeUrl="prodForm.imge_commodity_imge_url" :imgeUrlO="prodForm.imge_commodity_imge_urlO">
                      </imgPopover>
                      <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
                    </el-upload>
                    <span>大小不能超过3MB</span>
                  </div>
                  <div id="preview" class="vd_flex_right" style="display: flex; flex-direction: column" @paste="e => handlePaste(e, 2)">
                    <span><i class="el-icon-s-opportunity" style="color: #fb894c"></i>点击此处并按Ctrl+V粘贴</span>
                    <el-tooltip content="清除商品签字样" effect="dark" placement="right" style="margin-left: auto">
                      <el-button icon="el-icon-delete" type="text" @click="clearImage(2)"></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="样品图" prop="imge_craft">
                <div class="vd_dis">
                  <div>
                    <el-upload
                      class="upload-demo"
                      drag
                      disabled
                      :action="uploadUrl"
                      :data="dataBody"
                      :show-file-list="false"
                      :on-success="res => handleAvatarSuccess(res, 3)"
                      :before-upload="beforeAvatarUpload"
                      ref="craftImge"
                    >
                      <imgPopover
                        v-if="prodForm.imge_craft_imge_url"
                        :imgeUrl="prodForm.imge_craft_imge_url + '_m.jpg'"
                        :imgeUrlO="prodForm.imge_craft_imge_urlO + '_m.jpg'"
                      >
                      </imgPopover>
                      <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
                    </el-upload>
                  </div>
                  <!--                  <div id="preview" @paste="handlePaste" class="vd_flex_right">
                    <span><i class="el-icon-s-opportunity" style="color: #fb894c"></i>点击此处并按Ctrl+V粘贴</span>
                  </div>-->
                </div>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <h3 style="color: #606266" class="vg_mb_5">详细信息</h3>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <!--<el-col :span="24" class="vg_mb_5" v-if="prodForm.prod_attr !== '1'">-->
            <!--  <el-button type="primary" size="small" @click="dialogVisible4 = true">导入文本信息</el-button>-->
            <!--</el-col>-->
            <el-col :span="8" class="vg_mb_5">
              <el-form-item :rules="[{ required: prodForm.prod_attr !== '2', message: ' ' }]" label="产品品类" prop="prod_category">
                <el-cascader
                  ref="elCascader"
                  :props="{ emitPath: true }"
                  size="small"
                  v-model="prodForm.prod_category"
                  placeholder="请选择"
                  :options="prodCategoryOptions"
                  filterable
                  @change="cascaderChange"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :md="24" class="vg_mb_5">
              <el-form-item :label="prodForm.prod_attr === '2' ? '中文描述' : '英文规格'" prop="description">
                <el-input v-model="prodForm.description" type="textarea" :rows="3" show-word-limit placeholder="请填写"></el-input>
              </el-form-item>
              <el-link
                v-if="prodForm.prod_attr !== '3' && prodForm.prod_attr !== '1'"
                class="vg_pointer"
                style="position: relative; left: 52px; bottom: 50px"
                type="primary"
                @click="dialogVisible4 = true"
                >选择
              </el-link>
            </el-col>
            <el-col :md="24" class="vg_mb_5">
              <el-form-item v-if="prodForm.prod_attr === '3'" label="中文描述" prop="description_partcn">
                <el-input v-model="prodForm.description_partcn" type="textarea" :rows="3" show-word-limit placeholder="请填写"></el-input>
              </el-form-item>
              <el-link
                v-if="prodForm.prod_attr === '3'"
                class="vg_pointer"
                style="position: relative; left: 52px; bottom: 50px"
                type="primary"
                @click="dialogVisible4 = true"
                >选择
              </el-link>
            </el-col>
            <!--<el-col :span="8" class="vg_mb_5">-->
            <!--  <el-form-item label="产品类别" prop="prod_category">-->
            <!--    <el-select filterable v-model="prodForm.prod_category" disabled placeholder="请选择" @change="category" size="small">-->
            <!--      <el-option v-for="item in ProdCategory" :key="item.param1" :label="item.param1" :value="item.param1"></el-option>-->
            <!--    </el-select>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :span="24" class="vg_mb_5" v-if="prodForm.prod_category === '商检类'">-->
            <!--  <el-form-item label="产品类型" prop="product_type" :rules="{ required: true }" disabled>-->
            <!--    <el-radio v-model="prodForm.prodDetailed.product_type" @change="productTypeChange('餐垫')" label="餐垫"> 餐垫 </el-radio>-->
            <!--    <el-radio v-model="prodForm.prodDetailed.product_type" @change="productTypeChange('木制品')" label="木制品"-->
            <!--      >木制品-->
            <!--    </el-radio>-->
            <!--    <el-radio v-model="prodForm.prodDetailed.product_type" @change="productTypeChange('食品')" label="食品"> 食品 </el-radio>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--            <el-col :span="8" class="vg_mb_5" v-if="prodForm.prod_category === '商检类'">-->
            <!--              <el-form-item label="英文描述" prop="prod_espec">-->
            <!--                <el-input v-model="prodForm.prodDetailed.description" show-word-limit placeholder="请填写英文描述"></el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--            <el-col :span="8" class="vg_mb_5" v-if="prodForm.prod_category === '商检类'">-->
            <!--              <el-form-item label="价格组成" prop="prod_espec">-->
            <!--                <el-input v-model="prodForm.prodDetailed.price_composition" show-word-limit placeholder="请填写价格组成"></el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--<el-col :span="24" class="vg_mb_5">-->
            <!--  <DynamicForm-->
            <!--    v-bind="$attrs"-->
            <!--    :total-width="mainForm.totalWidth"-->
            <!--    :split="mainForm.split"-->
            <!--    :form-list="mainForm.formProperties"-->
            <!--    :labelWidth="'120px'"-->
            <!--    ref="dynamicForm"-->
            <!--    class="dynamicForm"-->
            <!--  />-->
            <!--</el-col>-->
            <!--            <el-col :span="24" class="vg_mb_5">-->
            <!--              <el-form-item label="中文规格" prop="prod_spec">-->
            <!--                <el-input-->
            <!--                  maxlength="100"-->
            <!--                  v-model="prodForm.prod_spec"-->
            <!--                  type="textarea"-->
            <!--                  :rows="1"-->
            <!--                  show-word-limit-->
            <!--                  placeholder="请填写中文规格"-->
            <!--                >-->
            <!--                </el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--            <el-col :span="24" class="vg_mb_5">-->
            <!--              <el-form-item label="英文规格" prop="prod_espec">-->
            <!--                <el-input v-model="prodForm.prod_espec" type="textarea" :rows="1" show-word-limit placeholder="请填写英文规格"> </el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--            <el-col :span="24" class="vg_mb_5">-->
            <!--              <el-form-item label="产品描述" prop="prod_desc">-->
            <!--                <el-input v-model="prodForm.prod_desc" type="textarea" :rows="2" show-word-limit placeholder="请填写产品描述"> </el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <h3 style="color: #606266" class="vg_mb_5" v-if="prodForm.prod_sales === 1">发票信息</h3>
            <h3 style="color: #606266" class="vg_mb_5" v-if="prodForm.prod_sales === 2">报关信息</h3>
          </el-col>
          <el-col :span="24">
            <prodCustom v-if="prodForm.prod_sales" ref="prodCustom" :prodForm="prodForm"></prodCustom>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <h3 style="color: #606266" class="vg_mb_5">包装信息</h3>
          </el-col>
          <el-col :span="24">
            <prodSpec :prodForm="prodForm"></prodSpec>
          </el-col>
        </el-row>
        <!--            <el-col :span="12">
              <el-form-item label="更改号" prop="prod_suffix">
                <el-input
                  maxlength="2"
                  v-model="prodForm.prod_suffix"
                  @input="prodForm.prod_suffix = helper.keepTNum(prodForm.prod_suffix)"
                  placeholder="请填写更改号"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>-->
        <el-col :span="24" class="vg_mb_5" v-if="prodForm.prod_attr !== '1'">
          <h3 style="color: #606266" class="vg_mb_5">供应商信息</h3>
          <el-col :span="24">
            <prodPartsInfo :prodForm="prodForm" @changePart="changePart"></prodPartsInfo>
          </el-col>
        </el-col>
        <el-col :span="24" class="vg_mb_5">
          <h3 style="color: #606266" class="vg_mb_5">其他信息</h3>
          <el-col :span="24" class="vg_mb_5">
            <el-form-item label="是否委外">
              <el-radio-group v-model="prodForm.prod_out">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否商检">
              <el-radio-group v-model="prodForm.prod_inspection">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :span="24" class="vg_mb_5" v-if="prodForm.prod_attr !== '2'">
          <div v-if="prodForm.is_suit === '1'">
            <h3 style="color: #606266" class="vg_mb_5">关联信息</h3>
            <el-col :span="24" class="vg_mb_5">
              <ProdAssociated ref="prodAssociated"></ProdAssociated>
            </el-col>
          </div>
          <div>
            <h3 style="color: #606266" class="vg_mb_5">部件信息</h3>
            <el-col :span="24" class="vg_mb_5">
              <ProdPartsList ref="prodPartsList" :ProdCategory="ProdCategory" :prodForm="prodForm" @addProdPartList="addProdPartList" />
            </el-col>
          </div>
          <!--          <h3 style="color: #606266" class="vg_mb_5">材料信息</h3>-->
          <el-col :span="24" class="vg_mb_5">
            <el-tabs v-model="activeName" type="border-card">
              <el-tab-pane label="材料信息" name="first" :key="'first'">
                <ProdMasc ref="ProdMasc" :prodForm="prodForm"></ProdMasc>
              </el-tab-pane>
              <el-tab-pane label="猫草信息" name="second" :key="'second'">
                <ProdCatnipList :prodForm="prodForm" :prodUnitList="prodUnitList" :isShow="false" />
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <!--    <el-dialog title="委托打样单" :visible.sync="dialogVisible1" width="70%">-->
    <!--      <ProdRequList @changePordNo="changePordNo"></ProdRequList>-->
    <!--    </el-dialog>-->
    <el-dialog title="产品设计单" :visible.sync="dialogVisible1" width="70%">
      <ProdDesiList @changePordNo="changePordNo"></ProdDesiList>
    </el-dialog>
    <el-dialog title="询价单" :visible.sync="dialogVisible2" width="70%">
      <ProdRqutList @changePordNo="changePordNo"></ProdRqutList>
    </el-dialog>
    <el-dialog title="客户信息" :visible.sync="dialogVisible3" width="70%">
      <ProdCustList @changeCustAbbr="changeCustAbbr"></ProdCustList>
    </el-dialog>
    <el-dialog title="导入文本信息" :visible.sync="dialogVisible4" width="70%">
      <ClauList @inputClau="inputClau"></ClauList>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { prodAPI } from '@api/modules/prod';
import { imgeAPI } from '@api/modules/imge';
import { optnAPI } from '@api/modules/optn';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import helper from '@assets/js/helper';
import imgPopover from '@/views/component/imgPopover';
import prodCustom from './Componet/ProdCustom.vue';
import prodSpec from './Componet/ProdSpec.vue';
import prodPartsInfo from './Componet/ProdPartsInfo.vue';
import ProdPartsList from './Componet/ProdPartsList.vue';
import ProdMasc from './Componet/ProdMasc.vue';
import ProdRequList from './Componet/ProdRequList.vue';
import ProdRqutList from './Componet/ProdRqutList.vue';
import ProdCustList from './Componet/ProdCustList.vue';
import ClauList from './Componet/ClauList.vue';
import ProdAssociated from '@/views/MessageManagement/ProdManage/TabChild/Componet/ProdAssociated.vue';
import { keep4Decimal } from '@assets/js/regExUtil';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { stffAPI } from '@api/modules/staff';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import { other, prodCategoryOptions } from '@/views/MessageManagement/ProdManage/TabChild/ProdDetails';
import ProdDesiList from '@/views/MessageManagement/ProdManage/TabChild/Componet/ProdDesiList.vue';
import { getDataCenterByPermId, getDept } from '@api/public';
import { getArrayIds } from '@assets/js/arrayUtils';
import ProdCatnipList from '@/views/MessageManagement/ProdManage/TabChild/Componet/ProdCatnipList.vue';
import { custAPI } from '@api/modules/cust';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ProdAddMain',
  components: {
    ProdCatnipList,
    ProdDesiList,
    DynamicForm,
    ProdAssociated,
    addHeader,
    inputUser,
    imgPopover,
    prodCustom,
    prodSpec,
    prodPartsInfo,
    ProdPartsList,
    ProdMasc,
    ProdRequList,
    ProdRqutList,
    ProdCustList,
    ClauList
  },
  data() {
    return {
      rules: {
        prod_attr: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_no: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_name: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_ename: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_cust_no: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_id: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_brand: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_mainsup: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_unit: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_hscode: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_use: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_usd: [{ required: true, trigger: 'blur', message: ' ' }],
        is_suit: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_ilk: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_id: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_price: [{ required: true, trigger: 'blur', message: ' ' }],
        description: [{ required: true, trigger: 'blur', message: ' ' }],
        description_partcn: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      activeName: 'first',
      ProdCategory: [],
      prodCategoryOptions: cloneDeep(prodCategoryOptions),
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(other)
      },
      ilkList: [
        { id: 0, name: '新款' },
        { id: 1, name: '修改' },
        { id: 2, name: '翻单' }
      ],
      prodNoMaxLength: 7,
      prodNoDisabled: false,
      ifProdAttr: true,
      prodForm: {
        prod_attr: '1',
        is_suit: '2',
        requ_smpl_id: null,
        rqut_id: null,
        prod_no: null,
        prod_poststfix: null,
        prod_partno: null,
        prod_cust_no: null,
        cust_abbr: null,
        cust_id: null,
        prod_usd: null,
        cust_currency: 'USD',
        prod_dusd: null,
        prod_unit: null,
        prod_name: null,
        prod_ename: null,
        prod_spec: null,
        prod_espec: null,
        prod_desc: null,
        prod_hscode: null,
        prod_hsname: null,
        prod_ehsname: null,
        prod_hsinrate: 13.0,
        prod_hsoutrate: 13.0,
        prod_bar: null,
        prod_inbar: null,
        prod_outbar: null,
        prod_boxl: null,
        prod_boxw: null,
        prod_boxh: null,
        prod_vol: null,
        prod_qpb: null,
        prod_nweight: null,
        prod_qpc: null,
        prod_gweight: null,
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        imge_commodity: null,
        imge_commodity_imge_url: '',
        imge_commodity_imge_urlO: '',
        imge_craft: null,
        imge_craft_imge_url: '',
        imge_craft_imge_urlO: '',
        supp_id: null,
        supp_ordr_total: null,
        prod_mainsup: '0',
        prod_price: null,
        prod_use: 1,
        prod_out: '2',
        prod_inspection: '2',
        prod_part_list: [], //部件
        prod_mtrb_list: [], //材料
        prod_catnip_list: [], //猫草
        prodList: [], //关联
        prod_category: '',
        description: '',
        description_partcn: '',
        prodDetailed: {},
        qarma: 0,
        scon_stff_id: '',
        prod_sales: '',
        cust_brand: ''
      },
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 1000,
        y: 1000
      },
      prodUnitList: [],
      custs: [],
      stffList: [],
      custCurrencyList: [],
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      associatedDialogVisible: false,
      isBussinessDept: false
    };
  },
  created() {
    this.initData();
  },
  async mounted() {
    this.prodForm.prod_unit = '个';
    this.prodForm.scon_stff_id = this.$cookies.get('userInfo').stff_id;
    this.syncData();
    this.prodCategoryOptions = Array.from(await getDataCenterByPermId({ id: 10032 }), item => {
      let obj = { value: item.param1, label: item.param1, param3: item.param3, param4: item.param4 };
      if (item.param2)
        obj.children = Array.from(item.param2.split(','), subItem => {
          return { value: subItem, label: subItem };
        });
      return obj;
    });
    let find = this.$refs.prodPartsList?.partTableProperties.find(x => x.label === '中文名称');
    if (find) find.subItem.required = this.prodForm.prod_attr === '1';
  },
  methods: {
    syncData() {
      let syncData = this.$route.params.syncData;
      if (syncData) {
        this.prodForm = syncData;
        this.prodForm.prod_mainsup += '';
        this.prodAttrChange();
        this.prodForm.status = 2;
      }
      const { addType } = this.$route.query;
      if (addType === '1') {
        this.prodForm['cust_currency'] = 'RMB';
        this.prodForm['prod_sales'] = 1;
        if (this.prodForm['prod_sales'] === 1) this.rules.prod_hscode[0].required = false;
        this.prodForm.prod_part_list.forEach(item => {
          item['cust_currency'] = 'RMB';
          item['prod_sales'] = 1;
          item['prod_unit'] = '个';
        });
      } else {
        this.prodForm['cust_currency'] = 'USD';
        this.prodForm['prod_sales'] = 2;
        this.prodForm.prod_part_list.forEach(item => {
          item['cust_currency'] = 'USD';
          item['prod_sales'] = 2;
          item['prod_unit'] = '个';
        });
      }
      // this.prodForm['prod_unit'] = '个';
      if (this.prodForm.prod_attr === '1') this.rules.prod_usd[0].required = true;
      else this.rules.prod_usd[0].required = this.prodForm.prod_attr !== '2';
      //this.mainForm.formProperties.forEach(item => (item.model = this.prodForm.prodDetailed[item.prop]));
      //判断内贸外贸详细信息必填项
      //this.mainForm.formProperties.find(({ prop }) => prop === 'description').rules.required = this.prodForm.prod_sales !== 1;
      //判断内贸外销
    },
    keep4Decimal,
    keepLetter2AndNumber5(val) {
      let prefixStr = val
        .substring(0, 2)
        ?.replace(/[^A-Za-z]/g, '')
        .toUpperCase();
      let subStr = val.substring(2, val.length)?.replace(/[^0-9]/g, '');
      return prefixStr + subStr;
    },
    async initData() {
      this.getCusts();
      this.getStffList();
      this.getProdRankList();
      this.getCustCurrency();
      this.getProdTypeList();
      let yewu = await getDept('业务');
      let neimao = await getDept('内贸');
      let dianshang = await getDept('电商');
      let it = await getDept('IT部');
      this.isBussinessDept = getArrayIds([...yewu, ...neimao, ...dianshang, ...it], 'value').includes(Number(this.$cookies.get('userInfo').dept_id));
    },
    getCusts() {
      get(custAPI.getAllCustsV1, { status: 2 })
        .then(res => {
          if (res.data.code === 0) {
            this.custs = res.data.data.list;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    changeFormProperties(arr, type) {
      if (type === '1') {
        arr[0].rules.required = true;
      } else arr[0].rules.required = type !== '2';
      return arr;
    },
    productTypeChange(val) {
      // let prodDetailed = this.$refs.dynamicForm.getFormModel();
      // switch (val) {
      //   case '餐垫':
      //     this.mainForm.formProperties = this.changeFormProperties(cloneDeep(diningMat), this.prodForm.prod_attr);
      //     break;
      //   case '木制品':
      //     this.mainForm.formProperties = this.changeFormProperties(cloneDeep(woodwork), this.prodForm.prod_attr);
      //     break;
      //   case '食品':
      //     this.mainForm.formProperties = this.changeFormProperties(cloneDeep(food), this.prodForm.prod_attr);
      //     break;
      // }
      // this.mainForm.formProperties.forEach(item => (item.model = prodDetailed[item.prop]));
    },
    category(val) {
      /*      let prodDetailed = this.$refs.dynamicForm.getFormModel();
      switch (val) {
        case '商检类':
          this.mainForm.formProperties = cloneDeep(inspFrom);
          this.prodForm.prod_category = '商检类';
          this.prodForm.prodDetailed.product_type = '餐垫';
          this.productTypeChange('餐垫');
          break;
        case '宠物包':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(petBag), this.prodForm.prod_attr);
          break;
        case '毛绒玩具':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(plushToys), this.prodForm.prod_attr);
          break;
        case '织带':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(webbing), this.prodForm.prod_attr);
          break;
        case '汗衫布绳':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(sweatshirt), this.prodForm.prod_attr);
          break;
        case '宠物衣服':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(petClothing), this.prodForm.prod_attr);
          break;
        case 'TPR':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(tpr), this.prodForm.prod_attr);
          break;
        case '宠物配饰（帽子、领结、围巾等）':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(petAccessories), this.prodForm.prod_attr);
          break;
        case '尼龙':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(nylon), this.prodForm.prod_attr);
          break;
        case '橡胶':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(rubber), this.prodForm.prod_attr);
          break;
        case '宠物碗':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(petBowl), this.prodForm.prod_attr);
          break;
        case '链条、项圈、胸背':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(chain), this.prodForm.prod_attr);
          break;
        case '宠物窝':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(petNest), this.prodForm.prod_attr);
          break;
        case '其他':
          this.mainForm.formProperties = cloneDeep(other);
          break;
      }
      this.mainForm.formProperties.forEach(item => (item.model = prodDetailed[item.prop]));*/
    },
    getProdTypeList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10030 })
        .then(res => {
          if (res.data.code === 0) {
            this.ProdCategory = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let partDynamicUTable = (await this.$refs.prodPartsList?.$refs.partDynamicUTable.fullValidateMsg()) || null;
          let mtrlDynamicUTable = (await this.$refs.ProdMasc?.$refs.mtrlDynamicUTable.fullValidateMsg()) || null;
          this.$refs[formName].validate((valid, object) => {
            if (valid && !partDynamicUTable && !mtrlDynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (partDynamicUTable) tempStr += `部件子表-${partDynamicUTable}`;
              if (mtrlDynamicUTable) tempStr += `材料子表-${mtrlDynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch();
    },
    // 保存
    saveInfo() {
      let isSub = false;
      this.prodForm.prod_part_list.forEach(item => {
        if (typeof item.part_id === 'undefined' || !item.part_id) {
          if (!item.submit) {
            isSub = true;
          }
        }
      });
      if (isSub) {
        return this.$message.warning('请填写部件信息的补充信息！');
      }
      if (this.prodForm.is_suit === '1') {
        this.prodForm.prodList = this.$refs.prodAssociated.tableData;
        this.prodForm.prod_ids = Array.from(this.prodForm.prodList, ({ prod_id }) => prod_id).join(',');
      }
      const staffForm = this.$refs.userData.getData();
      let prodcopy = JSON.parse(JSON.stringify(this.prodForm));
      let prodForm = Object.assign(prodcopy, staffForm);
      prodForm.personal = this.$cookies.get('userInfo').personal;
      if (prodForm.prod_category) prodForm.prod_category = prodForm.prod_category[prodForm.prod_category.length - 1];
      if (prodForm.prod_attr === '2') {
        prodForm.prod_mtrb_list = [];
      }
      post(prodAPI.addProd, prodForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.$store.commit('setProdInfoSyncData', {});
            const props = this.$route.query;
            this.$EventBus.$emit('close_current_tags');
            this.$router.replace({
              path: '/prod_edit',
              query: {
                key: UrlEncode.encode(
                  JSON.stringify({
                    perm_id: props.perm_id,
                    form_id: res.data.data.form_id,
                    prod_attr: res.data.data.prod_attr
                  })
                )
              }
            });
            this.resetForm('prodForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    isDescriptionRules() {
      if (this.mainForm.prod_attr === 2) {
        return { required: true, message: ' ' };
      } else {
        return { required: false, message: ' ' };
      }
    },
    //导入文本信息
    inputClau(row) {
      if (this.prodForm.prod_attr === '2') this.prodForm.description = row;
      if (this.prodForm.prod_attr === '3') this.prodForm.description_partcn = row;
      this.dialogVisible4 = false;
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('prodForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 打开我司货号导入框
    importPordNo() {
      if (this.prodForm.prod_attr === '1' || this.prodForm.prod_attr === '3') {
        this.dialogVisible1 = true;
      } else if (this.prodForm.prod_attr === '2') {
        this.dialogVisible2 = true;
      }
    },
    // 导入我司货号传值
    async changePordNo(val) {
      let importObj = JSON.parse(JSON.stringify(val));
      this.prodForm.prod_no = importObj.prod_no;
      this.prodForm.prod_name = importObj.prod_name;
      this.prodNoDisabled = true;
      this.prodNoMaxLength = 7;
      this.dialogVisible2 = false;
      this.dialogVisible1 = false;
    },
    // 属性变化
    async prodAttrChange() {
      this.ifProdAttr = true;
      let find = this.$refs.prodPartsList?.partTableProperties.find(x => x.label === '中文名称');
      if (this.prodForm.prod_attr === '1') {
        this.prodForm.supp_id = null;
        this.prodForm.supp_ordr_total = null;
        this.prodForm.prod_mainsup = '0';
        this.prodForm.prod_price = null;
        this.prodForm.prod_use = null;
        this.rules.prod_usd[0].required = true;
        if (find) find.subItem.required = true;
      } else if (this.prodForm.prod_attr === '2') {
        this.prodForm.prod_part_list = [];
        this.rules.prod_usd[0].required = false;
        this.ifProdAttr = false;
        this.prodNoDisabled = false;
        if (find) find.subItem.required = false;
      } else {
        this.prodForm.is_suit = '2';
        this.prodForm.prod_mainsup = '1';
        this.rules.prod_usd[0].required = true;
        //同步的数据并且只有新增时 并且为商品及部件时
        if (!this.prodForm.prod_id && this.prodForm.autoInc && this.prodForm.prod_attr === '3') {
          this.prodForm.prod_part_list.forEach(item => {
            item.prod_mainsup = Number(1);
          });
        }
        if (find) find.subItem.required = false;
      }
    },
    // 客户简称传值
    changeCustAbbr(val) {
      this.prodForm.cust_abbr = val.cust_abbr;
      this.prodForm.cust_id = val.cust_id;
      this.dialogVisible3 = false;
    },
    addProdPartList() {
      this.prodForm.prod_part_list.push({
        prod_no: this.prodForm.prod_no,
        prod_cust_no: this.prodForm.prod_cust_no,
        prod_price: '',
        prod_use: '',
        prod_partno: '',
        prod_poststfix: '',
        supplementInfo: { prod_hscode: '', prod_spec: '', prod_sales: this.prodForm.prod_sales },
        prod_category: this.prodForm.prod_category ? this.prodForm.prod_category[this.prodForm.prod_category.length - 1] : '',
        prodDetailed: this.prodForm.prodDetailed,
        submit: false,
        prod_sales: this.prodForm.prod_sales,
        prod_unit: '个'
      });
    },
    //从组件里获取值
    updateProdDetailed() {
      // let product_type = this.prodForm.prodDetailed.product_type;
      // this.prodForm.prodDetailed = this.$refs.dynamicForm.getFormModel();
      // this.prodForm.prodDetailed.product_type = product_type;
    },
    // 获取币种
    getCustCurrency() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.custCurrencyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取稿件等级
    getProdRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.prodUnitList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 成功上传
    handleAvatarSuccess(res, index) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        switch (index) {
          case 1:
            this.prodForm.imge_id = res.data.imge_id;
            this.prodForm.imge_url = this.helper.picUrl(urlEng, 'm', updateTime);
            this.prodForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            break;
          case 2:
            this.prodForm.imge_commodity = res.data.imge_id;
            this.prodForm.imge_commodity_imge_url = this.helper.picUrl(urlEng, 'm', updateTime);
            this.prodForm.imge_commodity_imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            break;
          case 3:
            // this.prodForm.imge_craft = res.data.imge_id;
            // this.prodForm.imge_craft_imge_url = this.helper.picUrl(urlEng, 'm', updateTime);
            // this.prodForm.imge_craft_imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            break;
        }
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 10MB!');
        return false;
      }
    },
    handlePaste(event, index) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;
      if (!items || items.length === 0) {
        this.$message.error('当前浏览器不支持本地');
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        this.$message.error('粘贴内容非图片');
        return;
      }
      this.$refs[`uploadMutiple${index}`].handleStart(file); // 将粘贴过来的图片加入预上传队列
      this.$refs[`uploadMutiple${index}`].submit(); // 提交图片上传队列
    },
    clearImage(index) {
      switch (index) {
        case 1:
          this.prodForm.imge_id = '';
          this.prodForm.imge_url = '';
          this.prodForm.imge_urlO = '';
          break;
        case 2:
          this.prodForm.imge_commodity = '';
          this.prodForm.imge_commodity_imge_url = '';
          this.prodForm.imge_commodity_imge_urlO = '';
          break;
        case 3:
          break;
      }
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      // this.category('其他');
      this.prodForm.prod_part_list = [];
      this.prodForm.prod_mtrb_list = [];
    },
    //获取销售经办人
    getStffList() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
          }
        })
        .catch(() => {});
    },
    changePart(column, val) {
      if (this.$route.query.UUID && this.prodForm.prod_attr === '3') {
        switch (column) {
          case 'prod_partno':
            this.prodForm.prod_part_list.forEach(item => this.$set(item, 'prod_partno', this.prodForm.prod_partno));
            break;
          case 'supp_abbr':
            this.prodForm.prod_part_list.forEach(item => this.$set(item, 'supp_abbr', val));
            break;
          case 'prod_price':
            this.prodForm.prod_part_list.forEach(item => this.$set(item, 'prod_price', val));
            break;
          case 'prod_use':
            this.prodForm.prod_part_list.forEach(item => this.$set(item, 'prod_use', val));
            break;
        }
      }
    },
    cascaderChange(val) {
      if (this.prodForm.prod_attr === '1') return;
      let { param3, param4 } = this.prodCategoryOptions.find(x => x.label === val[0]);
      let param3Arr = param3?.split('/*/') || [];
      let param4Arr = param4?.split('/*/') || [];
      let result = '';
      param3Arr.forEach((item, index) => {
        if (param4Arr[index]) result += `${item}${this.prodForm[param4Arr[index]] || ''}\n`;
        else result += item + '\n';
      });
      if (this.prodForm.prod_attr === '2') this.prodForm.description += result;
      if (this.prodForm.prod_attr === '3') this.prodForm.description_partcn += result;
    }
  },
  computed: {
    custBrandList() {
      if (!this.prodForm.cust_id) {
        this.prodForm.cust_brand = '';
        return [];
      }
      return this.custs.find(x => x.cust_id === this.prodForm.cust_id)?.cust_brand_list || [];
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

::v-deep .el-upload--text {
  height: 200px;
  width: 200px;
}

::v-deep .el-upload-dragger {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vd_dis {
  display: flex;
  align-items: center;
}

.vd_flex_right {
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 10px;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
</style>
